import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import analytics from './modules/analytics'
import users from './modules/users'
import tagging from './modules/tagging'
import statistics from './modules/statistics'
import types from './modules/types'
import sidebar from './modules/sidebar'
import cases from './modules/cases'
import monitoring from './modules/monitoring'
import scoringProfiles from "@/store/modules/scoring-profiles";
import alerts from './modules/alerts'
import entity from './modules/entity'
import counterparty from './modules/counterparty'
import rates from './modules/rates'
import directions from './modules/directions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alerts,
    user,
    users,
    cases,
    types,
    sidebar,
    tagging,
    analytics,
    monitoring,
    statistics,
    scoringProfiles,
    entity,
    counterparty,
    rates,
    directions,
  },
})
